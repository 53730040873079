import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static targets = [
    "sku", "description", "price",
    "net", "tax", "total",
    "quantity", "taxcode", "destroy"
  ]

  static values = {
    supplierProducts: Array,
    price: Number,
    net: Number,
    tax: Number,
    quantity: Number,
    total: Number
  }

  static outlets = [ "net-tax-total" ]

  connect() {
    this.priceValue = this.priceTarget.value
    this.defaultProduct = this.element.dataset.productId
  }

  supplierProductsValueChanged() {

    if( this.defaultProduct !== 'EMPTY' && this.defaultProduct != null ) {
      const selectedProduct = this.supplierProductsValue.find(product => product.sku === this.defaultProduct);

      if(selectedProduct) {
        this.skuTarget.value = selectedProduct.sku;
        this.descriptionTarget.value = selectedProduct.name;
        this.priceTarget.value = selectedProduct.unitPrice;
      }

    }

  }

  updatePrice(event) {

    const selectedProduct = this.supplierProductsValue.find(product => product.name === event || product.sku === event);

    if (selectedProduct) {
      if (!("unitPrice" in selectedProduct)) {
        const instance = tippy(this.priceTarget, {
          trigger: 'manual',
          content: "No price available in the Purchase Order currency",
          duration: [0, 1000],
          hideOnClick: true,
          interactive: true,
          showOnCreate: true,
          appendTo: document.body
        });
      }
      this.priceTarget.value = selectedProduct.unitPrice;
      this.calculate();
      this.descriptionTarget.value = selectedProduct.name;
      this.updateSku(selectedProduct.sku)
      this.updateDescription(selectedProduct.name)
    }
  }

  updateSku(value) {
    $(this.skuTarget)[0].selectize.setValue(value);
  }

  updateDescription(value) {
    $(this.descriptionTarget)[0].selectize.setValue(value);
  }

  calculate() {
    this.netTaxTotalOutlet.updateSubtotals();
  }
}
