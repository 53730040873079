import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static targets = [
    "currency", "lineItem", "totalPrice", "totalBuyPrice", "totalWeight",
    "totalVolume", "totalMargin", "marginPercentage", "products", "accountId"
  ]

  static values = {
    accountId: String
  }

  connect() {
    this.accountIdValue = this.accountIdTarget.value;
    this.calculate()
    this.initializeCurrencyTip()
    this.handleCurrencyState()
  }

  calculate() {
    const totals = this.lineItemTargets.reduce((acc, item) => {
      if (item.querySelector('[data-field="destroy"]').value === "1") {
        return acc;
      }

      const quantity = parseFloat(item.querySelector('[data-field="quantity"]').value) || 0
      const price = parseFloat(item.querySelector('[data-field="price"]').value) || 0
      const buyPrice = parseFloat(item.querySelector('[data-field="buy_price"]').value) || 0
      const weight = parseFloat(item.dataset.weight) || 0
      const volume = parseFloat(item.dataset.volume) || 0
      const itemTotalPrice = quantity * price;

      item.querySelector('[data-field="item_total_price"]').textContent = this.formatCurrency(itemTotalPrice);

      acc.totalPrice += itemTotalPrice
      acc.totalBuyPrice += quantity * buyPrice
      acc.totalWeight += quantity * weight
      acc.totalVolume += quantity * volume

      return acc
    }, { totalPrice: 0, totalBuyPrice: 0, totalWeight: 0, totalVolume: 0 })

    const totalMargin = totals.totalPrice - totals.totalBuyPrice
    const marginPercentage = totals.totalPrice > 0 ? (totalMargin / totals.totalPrice) * 100 : 0

    this.totalPriceTarget.textContent = this.formatCurrency(totals.totalPrice)
    this.totalBuyPriceTarget.textContent = this.formatCurrency(totals.totalBuyPrice)
    this.totalWeightTarget.textContent = `${totals.totalWeight.toFixed(2)} kg`
    this.totalVolumeTarget.textContent = `${totals.totalVolume.toFixed(2)} cm³`
    this.totalMarginTarget.textContent = this.formatCurrency(totalMargin)
    this.marginPercentageTarget.textContent = `${marginPercentage.toFixed(2)}%`
  }

  formatCurrency(amount) {
    return `${amount.toFixed(2)}`
  }

  lineItemTargetConnected() {

  }

  lineItemTargetDisconnected() {
    this.calculate()
  }

  handleCurrencyState() {
    const currencyId = parseInt(this.currencyTarget.value) || -1;
    console.log("Currency state", currencyId)
    if (currencyId !== -1) {
      this.loadProducts()
    } else {
      this.clearProducts()
    }
  }

  clearProducts() {
    const productsList = this.productsTarget.querySelector('.products-list');
    productsList.innerHTML = '<p>Please select a currency to view products.</p>';
  }

  async loadProducts() {
    try {
      const products = await this.fetchProducts()
      this.renderProductList(products)
    } catch (error) {
      console.error("Error loading products:", error)
    }
  }

  openProductsModal() {
    if (this.currencyTarget.value === "") {
      this.currencyTip.show();
      setTimeout(() => this.currencyTip.hide(), 3000);
    } else {
      MicroModal.show("so-products");
    }
  }

  renderProductList(products) {
    this.allProducts = products || [];
    this.renderFilteredProducts(this.allProducts);
  }

  fetchProducts = async () => {
    const response = await fetch(`/a/${this.accountIdValue}/products.json`);
    return await response.json();
  }

  searchProducts = (event) => {
    const searchQuery = event.target.value.toLowerCase();

    if (!this.allProducts) return;

    const filteredProducts = this.allProducts.filter(product =>
      (
        product.name.toLowerCase().includes(searchQuery) ||
        product.sku.toLowerCase().includes(searchQuery)
      )
    );

    this.renderFilteredProducts(filteredProducts);
  }

  addProduct = (event) => {
    const productSku = event.target.dataset.sku;
    const selectedProductObject = this.allProducts.find(product => product.sku === productSku);
    const currencyId = parseInt(this.currencyTarget.value);

    const nestedFormElement = document.querySelector('[data-controller="nested-form"]');
    const nestedFormController = this.application.getControllerForElementAndIdentifier(nestedFormElement, "nested-form");

    if (nestedFormController) {
      nestedFormController.add(event, selectedProductObject);
    }

    const newLineItem = [...this.lineItemTargets].reverse().find(item => item.dataset.productId === productSku);

    if (newLineItem) {
      newLineItem.querySelector('[data-field="sku"]').value = selectedProductObject.sku;
      newLineItem.querySelector('[data-field="description"]').value = selectedProductObject.name;
      newLineItem.querySelector('[data-field="quantity"]').value = 1;
      newLineItem.querySelector('[data-field="price"]').value = selectedProductObject.sale_price_ids[currencyId] || '';

      const purchaseCost = selectedProductObject.purchase_costs && selectedProductObject.purchase_costs[currencyId]?.[0]?.amount || '';
      newLineItem.querySelector('[data-field="buy_price"]').value = purchaseCost;

      const categorySelect = newLineItem.querySelector('[data-field="category_id"]');
      if (categorySelect && selectedProductObject.category.id) {
        categorySelect.value = selectedProductObject.category.id;
      }
      newLineItem.dataset.weight = selectedProductObject.weight || 0;
      newLineItem.dataset.volume = selectedProductObject.volume || 0;

      this.calculate();
    }
  }

  renderFilteredProducts(products) {
    const currencyId = parseInt(this.currencyTarget.value) || -1;
    const productsList = this.productsTarget.querySelector('.products-list');

    if (currencyId === -1) {
      productsList.innerHTML = '<p>Please select a currency to view products.</p>';
      return;
    }

    productsList.innerHTML = products.map(product => {
      const salePrice = product.sale_price_ids[currencyId] || 'N/A';
      const purchaseCost = product.purchase_costs && product.purchase_costs[currencyId]?.[0]?.amount || 'N/A';
      return `
        <div class="grid grid-cols-[2fr_1fr_4fr_1fr_1fr_auto] gap-4 items-center py-2">
          <span class="grow truncate">${product.sku}</span>
          <span class="grow">${new Date(product.updated_at).toLocaleDateString('en-GB')}</span>
          <span class="grow truncate">${product.name}</span>
          <span class="price justify-self-end">${salePrice}</span>
          <span class="price justify-self-end">${purchaseCost}</span>
          <button class="btn btn-primary" data-sku="${product.sku}">Add</button>
        </div>
      `;
    }).join('');

    productsList.querySelectorAll('button').forEach(button => {
      button.addEventListener('click', this.addProduct);
      this.calculate();
    });
  }

  initializeCurrencyTip() {
    this.currencyTip = tippy(this.element.querySelector('[data-action="click->sales-order#openProductsModal"]'), {
      content: "Please select a currency before adding items",
      trigger: 'manual',
      placement: 'top',
    });
  }
}