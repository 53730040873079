import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "company", "contact", "name", "lineOne", "lineTwo", "town", "county", "postCode", "country", "addressFields", "addressFieldsReadOnly"
  ]

  static values = {
    accountId: String,
    contacts: Array,
    selectedContact: String
  }

  connect() {
    // this.fetchContacts();
  }

  editAddress(event) {
    MicroModal.show( event.currentTarget.dataset.modal )
  }

  contactsValueChanged(event) {
    // Destroy the current Selectize object, if it exists
    let selectizeControl = this.contactTarget.selectize;
    if (selectizeControl) {
      selectizeControl.destroy();
    }

    $(this.contactTarget).selectize({
      options: this.contactsValue,
      optionGroupRegister: function (optgroup) {
        var capitalised = optgroup.charAt(0).toUpperCase() + optgroup.substring(1);
        var group = {
          label: 'Company: ' + capitalised
        };
        group[this.settings.optgroupValueField] = optgroup;
        return group;
      },
      onChange: this.fetchContact.bind(this),
      optgroupField: 'company',
      labelField: 'name',
      searchField: ['name'],
      sortField: 'name'
    });

    this.contactTarget.selectize.clear();

    if (this.contactsValue.length === 1) {
      const firstOption = Object.keys(this.contactTarget.selectize.options)[0]
      this.contactTarget.selectize.setValue( firstOption );
    }
  }


  updateAddress(event) {

    const formInputs = this.addressFieldsTarget.getElementsByTagName('input');
    const inputs = Array.from(formInputs).slice(1); // Exclude the first item
    const hasInformation = inputs.some(input => input.value !== '');
    const divElement = this.addressFieldsReadOnlyTarget.querySelector('div');

    if (hasInformation) {
      const formattedValues = inputs
        .filter(input => input.type !== 'submit' && input.value !== '')
        .map(input => input.value)
        .join('\n');

      divElement.innerText = formattedValues;
    } else {
      divElement.innerText = "No address provided";
    }

  }

  async fetchContacts() {
    if (this.companyTarget.value === ""){
      this.contactsValue = [];
      return;
    }

    try {
      const response = await fetch(`/a/${this.accountIdValue}/companies/${this.companyTarget.value}/contacts.json`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      this.contactsValue = data.map(contact => ({
        value: contact.id,
        name: contact.full_name,
        company: contact.company_name
      }));
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  }

  fetchContact() {
    if(this.contactTarget.value == "") {
      this.addressFieldsReadOnlyTarget.classList.add('hidden');
      return;
    } else {
      this.addressFieldsReadOnlyTarget.classList.remove('hidden');
    }
    fetch(`/a/${this.accountIdValue}/contacts/${this.contactTarget.value}.json`)
      .then(response => response.json())
      .then(data => this.populateAddress(data));
  }

  populateAddress(data) {
    const address = data.addresses?.[0] || {};

    this.nameTarget.value = `${data.first_name ?? ''} ${data.last_name ?? ''}`;
    this.companyTarget.value = data.company_id ?? '';

    this.lineOneTarget.value = address.line_one ?? '';
    this.lineTwoTarget.value = address.line_two ?? '';
    this.townTarget.value = address.town ?? '';
    this.countyTarget.value = address.county ?? '';
    this.postCodeTarget.value = address.post_code ?? '';
    this.countryTarget.value = address.country ?? '';

    const addressValues = Object.values(address).filter(value => value !== '').join('\n');
    this.addressFieldsReadOnlyTarget.querySelector('div').innerText = addressValues || 'No address provided';
  }

}
